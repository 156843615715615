const config = {
  APP_STAGE: "prod",
  AUTH0_DOMAIN: "dividends360.us.auth0.com",
  AUTH0_CLIENT_ID: "mcEMRKjtBAAey4B185rTdupY0aBDZWoq",
  LOGOUT_URL: "https://dividends360.com",
  RPC_API_URL: "https://sls-scus-prod-08fe43-apim.azure-api.net/rpc",
  YODLEE_API_URL: "https://production.api.yodlee.com/ysl",
  SENTRY_DSN: "https://526f2df07bcd4f748b4912b459f3f6bb@o556171.ingest.sentry.io/5941235",
  SOCKET_SERVER_URL: "https://d360-socket-server-prod.azurewebsites.net",
  TNC_URL: "https://figi360.com/terms-and-conditions",
  PLANNING_URL: "https://planning.app.dividends360.com",
  FRESHDESK_API_KEY: "<FRESHDESK_API_KEY>",
};

export default config;
